import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { FindVehicleTypeDto, PageResultDto, VehicleTypeDto } from '../dto'

@Injectable()
export class VehicleTypeService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findVehicleTypeDto: FindVehicleTypeDto) {
    return await this.httpService.get<PageResultDto<VehicleTypeDto>>('/admin/v1/vehicle-types', findVehicleTypeDto)
  }

  async create(createVehicleTypeDto: VehicleTypeDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/vehicle-types`, createVehicleTypeDto)
  }

  async update(id: string, updateVehicleTypeDto: Partial<VehicleTypeDto>) {
    return await this.httpService.put<Boolean>(`/admin/v1/vehicle-types/${id}`, updateVehicleTypeDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/vehicle-types/${id}`)
  }

  async sortOrder(vehicleTypeIds: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/vehicle-types/sort-order`, { vehicleTypeIds })
  }
}
