import { Injectable } from "@angular/core"

import { HttpService } from "./http.service"

import { DeliveryFeeDto, UpdateDeliveryFeeDto, CreateDeliveryFeeDto, VehicleBasedDeliveryFeeDto } from "../dto"

@Injectable()
export class VehicleBasedDeliveryFeeService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(vehicleTypeId: string) {
    return await this.httpService.get<VehicleBasedDeliveryFeeDto[]>('/admin/v1/vehicle-based-delivery-fee', { vehicleTypeId })
  }

  async delete(id: string) {
    return await this.httpService.delete<boolean>(`/admin/v1/vehicle-based-delivery-fee/${id}`)
  }

  async update(id: string, updateDeliveryFeeDto: UpdateDeliveryFeeDto) {
    return await this.httpService.put<boolean>(`/admin/v1/vehicle-based-delivery-fee/${id}`, updateDeliveryFeeDto)
  }

  async create(createDeliveryFeeDto: CreateDeliveryFeeDto) {
    return await this.httpService.post<boolean>(`/admin/v1/vehicle-based-delivery-fee`, createDeliveryFeeDto)
  }
}