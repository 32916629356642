import { Injectable } from '@angular/core'

import { BrandDto, CreateBrandDto, FindBrandDto, PageResultDto, UpdateBrandDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class BrandService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findBrandDto: FindBrandDto) {
    return await this.httpService.get<PageResultDto<BrandDto>>('/admin/v1/brands', findBrandDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<BrandDto>(`/admin/v1/brands/${id}`)
  }
  
  async create({ name, description, imageId, logoId }: CreateBrandDto)  {
    return await this.httpService.post<Boolean>('/admin/v1/brands', { 
      name,
      description,
      logoId,
      imageId
    })
  }
  
  async deleteById(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/brands/${id}`)
  }

  async updateImage(id: string,imageId: string) {
    return await this.httpService.put<Boolean>(`/admin/v1/brands/${id}/image`, { "fileId": imageId })
  }

  async updateLogo(id: string, logoId: string) {
    return await this.httpService.put<Boolean>(`/admin/v1/brands/${id}/logo`, { "fileId": logoId })
  }

  async update(id: string, updateBrandDto: UpdateBrandDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/brands/${id}`, updateBrandDto)
  }
}
