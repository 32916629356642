import { Injectable } from '@angular/core'

import { CreateOrUpdateServiceDto, ExportBookingResultDto, FindBookingDateFilterDto, FindDriverRatingDto, FindServiceDto, PageResultDto, ServiceDetailDto, ServiceDto, ServiceRatingDetailDto, ServiceRatingDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class ServiceService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findServiceDto: FindServiceDto) {
    return await this.httpService.get<PageResultDto<ServiceDto>>(`/admin/v1/services`, findServiceDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<ServiceDetailDto>(`/admin/v1/services/${id}`)
  }

  async create(createServiceDto: CreateOrUpdateServiceDto) {
    return await this.httpService.post<ServiceDto>(`/admin/v1/services`, createServiceDto)
  }

  async update(id: string, updateServiceDto: CreateOrUpdateServiceDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/services/${id}`, updateServiceDto)
  }

  async sortOrder(ids: string) {
    return await this.httpService.post<boolean>(`/admin/v1/services/sort-order`, { ids })
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/services/${id}`)
  }

  async findRatings(findDriverRatingDto: FindDriverRatingDto) {
    return await this.httpService.get<PageResultDto<ServiceRatingDto>>(`/admin/v1/services/ratings`, findDriverRatingDto)
  }

  async findOneRatings(serviceBookingNumber: string) {
    return await this.httpService.get<ServiceRatingDetailDto>(`/admin/v1/service-bookings/${serviceBookingNumber}/ratings`)
  }

  async exportRatingExcel(dateFilterDto: FindBookingDateFilterDto) {
    return await this.httpService.get<ExportBookingResultDto>(`/admin/v1/services/export-ratings`, dateFilterDto)
  }
}
