import { CommonModule } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ModuleWithProviders, NgModule } from '@angular/core'

import { HttpErrorInterceptor } from '@api/common'
import { StylesFilterPipe } from '@api/utils'

import { AuthStore, AuthQuery } from '../store/auth'

import { HttpService, AuthService, LocalStorageService, RewardService, CouponService, FileService, UserService, ProfileService, SettingService, LocationService, PageService, BankService, MerchantService, DriverService, PaymentRequestService, PaymentRefillService, PaymentTransactionService, WindowRef, BookingService, PromotionService, BankInfoService, PushNotificationService, DashboardService, BookingReasonService, DeliveryFeeService, VehicleTypeService, VehicleBasedDeliveryFeeService, StoreService, ProductCategoryService, BrandService, VoucherService, MenuService, BannerService, HolidayPriceRateService, ServiceCategoryService, ServicePriceListService, ProductService, ServiceService, OrderService, ServiceBookingService, BookingFlowService, FeaturedProductCategoryService, ProductDiscountService, DeliveryDiscountService, CustomMapService, ChannelService } from '@api/services'
import { ApiModuleOptions } from './api.module-options'
import { DragulaService } from 'ng2-dragula'

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [
    StylesFilterPipe
  ],
  providers:[
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    FileService,
    LocalStorageService,
    HttpService,
    AuthService,
    UserService,
    ProfileService,
    SettingService,
    LocationService,
    CouponService,
    BankService,
    PageService,
    AuthStore,
    AuthQuery,
    MerchantService,
    DriverService,
    PaymentRequestService,
    PaymentRefillService,
    PaymentTransactionService,
    BookingReasonService,
    BookingService,
    PromotionService,
    VehicleBasedDeliveryFeeService,
    BankInfoService,
    PushNotificationService,
    VehicleTypeService,
    DeliveryFeeService,
    DashboardService,
    RewardService,
    StoreService,
    ProductCategoryService,
    BrandService,
    VoucherService,
    MenuService,
    BannerService,
    HolidayPriceRateService,
    ServiceCategoryService,
    ServicePriceListService,
    ProductService,
    CustomMapService,
    ServiceService,
    ServiceBookingService,
    BookingFlowService,
    OrderService,
    FeaturedProductCategoryService,
    ProductDiscountService,
    DeliveryDiscountService,
    ChannelService,
    WindowRef,
    DragulaService
  ],
  exports: [
    StylesFilterPipe
  ]
})
export class ApiModule {
  static forRoot(options: ApiModuleOptions): ModuleWithProviders {
    // window['serverURL'] = options.serverURL
    
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiModuleOptions,
          useValue: options
        }
      ]
    }
  }
}
