import { Injectable } from '@angular/core'

import { CreateDeliveryDiscountDto, DeliveryDiscountDto, FindDeliveryDiscountDto, PageResultDto, ProductDiscountDto, UpdateDeliveryDiscountDto } from '@api/dto'

import { HttpService } from './http.service'

@Injectable()
export class DeliveryDiscountService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findDeliveryDiscountDto: FindDeliveryDiscountDto) {
    return await this.httpService.get<PageResultDto<DeliveryDiscountDto>>(`/admin/v1/delivery-discount`, findDeliveryDiscountDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<DeliveryDiscountDto>(`/admin/v1/delivery-discount/${id}`)
  }

  async create(createDeliveryDiscountDto: CreateDeliveryDiscountDto) {
    return await this.httpService.post<ProductDiscountDto>(`/admin/v1/delivery-discount`, createDeliveryDiscountDto)
  }

  async update(id: string, updateDeliveryDiscountDto: UpdateDeliveryDiscountDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/delivery-discount/${id}`, updateDeliveryDiscountDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/delivery-discount/${id}`)
  }
}
