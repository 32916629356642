import { Injectable } from '@angular/core'

import { BalanceDto, ExportBookingResultDto, FindBookingDateFilterDto, FindDriverDto, FindMerchantDto, MerchantDetailDto, MerchantDto, PageResultDto, UpdateMerchantDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class MerchantService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findMerchantDto: FindMerchantDto) {
    return await this.httpService.get<PageResultDto<MerchantDto>>('/admin/v1/merchants', findMerchantDto)
  }

  async findByOwnerIds(ownerIds: string[]) {
    return await this.httpService.get<MerchantDto[]>('/admin/v1/merchants/owners', { ownerIds })
  }

  async findBalance(findDriverDto: FindDriverDto) {
    return await this.httpService.get<PageResultDto<BalanceDto>>('/admin/v1/merchants/balance', findDriverDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<MerchantDetailDto>(`/admin/v1/merchants/${id}`)
  }

  async update(id: string, updateMerchantDto: UpdateMerchantDto) {
    return await this.httpService.put<boolean>(`/admin/v1/merchants/${id}`, updateMerchantDto)
  }
  
  async exportExcel(dateFilterDto: FindBookingDateFilterDto) {
    return await this.httpService.get<ExportBookingResultDto>(`/admin/v1/merchants/export-payment`, dateFilterDto)
  }

  async export({ startDate, endDate }: FindBookingDateFilterDto) {
    return await this.httpService.get<ExportBookingResultDto>(`/admin/v1/merchants/export`, { startDate, endDate })
  }
}
