import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { CreateOrUpdateServicePriceListDto, ServicePriceListDto } from '../dto'

@Injectable()
export class ServicePriceListService {
  constructor(
    private httpService: HttpService) {
  }

  async find(serviceId: string) {
    return await this.httpService.get<ServicePriceListDto[]>(`/admin/v1/service-price-list/${serviceId}`)
  }

  async update(serviceId: string, updateServicePriceListDto: CreateOrUpdateServicePriceListDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/service-price-list/${serviceId}`, updateServicePriceListDto)
  }

  async create(serviceId: string, createServicePriceListDto: CreateOrUpdateServicePriceListDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/service-price-list/${serviceId}`, createServicePriceListDto)
  }

  async sortOrder(serviceId: string, ids: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/service-price-list/${serviceId}/sort-order`, { ids })
  }
}
