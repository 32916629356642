import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { BalanceDto, CountPendingDriverDto, CreateDeliveryFeeDto, CreateOrUpdateStoreDto, CreateStoreBankInfoDto, ExportBookingResultDto, FileDto, FindBookingDateFilterDto, FindStoreDto, PageResultDto, StoreDetailDto, StoreDto, StoreSetupDto, UpdateDeliveryFeeDto, VehicleBasedDeliveryFeeDto } from '../dto'


@Injectable()
export class StoreService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findStoreDto: FindStoreDto) {
    return await this.httpService.get<PageResultDto<StoreDto>>('/admin/v1/stores', findStoreDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<StoreDetailDto>(`/admin/v1/stores/${id}`)
  }

  async findAttachment(id: string) {
    return await this.httpService.get<FileDto[]>(`/admin/v1/stores/${id}/attachments`)
  }

  async update(id: string, updateStoreDto: CreateOrUpdateStoreDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/stores/${id}`, updateStoreDto)
  }

  async updateCustomDelivery(id: string, custom: boolean) {
    if (custom) {
      return await this.httpService.patch<Boolean>(`/admin/v1/stores/${id}/enable-custom-delivery`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/stores/${id}/disable-custom-delivery`)
  }

  async create(createStoreDto: CreateOrUpdateStoreDto) {
    return await this.httpService.post<StoreDto>(`/admin/v1/stores`, createStoreDto)
  }

  async findPending({ searchText, limit, offset, type }: FindStoreDto) {
    return await this.httpService.get<PageResultDto<StoreSetupDto>>('/admin/v1/stores/setup', { searchText, limit, offset, type })
  }

  async findBalance(findDriverDto: FindStoreDto) {
    return await this.httpService.get<PageResultDto<BalanceDto>>('/admin/v1/stores/balance', findDriverDto)
  }

  async findOnePending(id: string) {
    return await this.httpService.get<StoreSetupDto>(`/admin/v1/stores/setup/${id}`)
  }

  async countPending() {
    return await this.httpService.get<CountPendingDriverDto>('/admin/v1/stores/setup/count')
  }

  async approve(id: string) {
    return await this.httpService.post<Boolean>(`/admin/v1/stores/setup/approve`, { storeSetupId: id })
  }

  async reject(id: string) {
    return await this.httpService.post<Boolean>(`/admin/v1/stores/setup/reject`, { storeSetupId: id })
  }

  async setServiceCategory(id: string, serviceCategoryIds: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/stores/${id}/service-categories`, { serviceCategoryIds })
  }

  async setProductCategory(id: string, productCategoryIds: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/stores/${id}/product-categories`, { productCategoryIds })
  }

  async findFeatured(findStoreDto: FindStoreDto) {
    return await this.httpService.get<PageResultDto<StoreDto>>(`/admin/v1/stores/featured`, findStoreDto)
  }

  async createFeatured(storeId: string) {
    return await this.httpService.post<Boolean>(`/admin/v1/stores/featured`, { storeId })
  }

  async sortOrderFeatured(storeIds: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/stores/featured/sort-order`, {storeIds})
  }

  async deleteFeatured(storeId: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/stores/featured/${storeId}`)
  }

  async exportExcel(dateFilterDto: FindBookingDateFilterDto) {
    return await this.httpService.get<ExportBookingResultDto>(`/admin/v1/stores/export-payment`, dateFilterDto)
  }

  async createBank(createStoreBankInfoDto: CreateStoreBankInfoDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/stores/bank-info`, createStoreBankInfoDto)
  }

  async updateBank(createStoreBankInfoDto: CreateStoreBankInfoDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/stores/bank-info`, createStoreBankInfoDto)
  }

  async findCustomDeliveryFee(id: string) {
    return await this.httpService.get<VehicleBasedDeliveryFeeDto[]>(`/admin/v1/stores/${id}/custom-delivery-fee`)
  }

  async updateCustomDeliveryFee(storeId: string, id: string, updateDeliveryFeeDto: UpdateDeliveryFeeDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/stores/${storeId}/custom-delivery-fee/${id}`, updateDeliveryFeeDto)
  }

  async deleteCustomDeliveryFee(storeId: string, id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/stores/${storeId}/custom-delivery-fee/${id}`)
  }

  async createCustomDeliveryFee(storeId: string, createDeliveryFeeDto: CreateDeliveryFeeDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/stores/${storeId}/custom-delivery-fee`, createDeliveryFeeDto)
  }
}
