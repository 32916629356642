import { Injectable } from '@angular/core'

import { UserDto, PageResultDto, FindUserDto, CreateUserDto, UpdateUserDto } from '../dto'
import { CreateOrUpdateRewardDto, FindRewardDto, RewardDetailDto, RewardDto } from '../dto/reward.dto'

import { HttpService } from './http.service'

@Injectable()
export class RewardService {
  constructor(
    private httpService: HttpService) {
  }

  async create(createRewardDto: CreateOrUpdateRewardDto) {
    return await this.httpService.post<RewardDto>('/admin/v1/rewards/', createRewardDto)
  }

  async find(findRewardDto: FindRewardDto) {
    return await this.httpService.get<PageResultDto<RewardDto>>('/admin/v1/rewards', findRewardDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<RewardDetailDto>(`/admin/v1/rewards/${id}`)
  }
  
  async update(id: string, updateRewardDto: CreateOrUpdateRewardDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/rewards/${id}`, updateRewardDto)
  }

  async destroy(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/rewards/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put(`/admin/v1/rewards/${id}/enable`)
    }
    
    return await this.httpService.put(`/admin/v1/rewards/${id}/disable`)
  }
}
