import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { HolidayPriceRateDto, CreateOrUpdateHolidayPriceRateDto } from '../dto'

@Injectable()
export class HolidayPriceRateService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<HolidayPriceRateDto[]>('/admin/v1/holiday-price-rate')
  }

  async update(id: string, updateHolidayRate: CreateOrUpdateHolidayPriceRateDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/holiday-price-rate/${id}`, updateHolidayRate)
  }

  async create(createHolidayRate: CreateOrUpdateHolidayPriceRateDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/holiday-price-rate`, createHolidayRate)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/holiday-price-rate/${id}`)
  }
}
