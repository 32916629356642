import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { CreateOrUpdateServiceCategoryDto, FindServiceCategoryDto, PageResultDto, ResultDto, ServiceCategoryDto, SettingDto } from '../dto'

@Injectable()
export class ServiceCategoryService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findServiceCategoryDto: FindServiceCategoryDto) {
    return await this.httpService.get<PageResultDto<ServiceCategoryDto>>('/admin/v1/service-categories', findServiceCategoryDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<ServiceCategoryDto>(`/admin/v1/service-categories/${id}`)
  }

  async update(id: string, updateServiceCategoryDto: CreateOrUpdateServiceCategoryDto) {
    return await this.httpService.put<SettingDto>(`/admin/v1/service-categories/${id}`, updateServiceCategoryDto)
  }

  async create(createServiceCategoryDto: CreateOrUpdateServiceCategoryDto) {
    return await this.httpService.post<ServiceCategoryDto>(`/admin/v1/service-categories`, createServiceCategoryDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/service-categories/${id}`)
  }
}
