import { Injectable } from '@angular/core'

import { PageResultDto, FindBookingDto, BookingDto, BookingDetailDto, CountBookingDto, UpdateBookingStatusDto, ExportBookingResultDto, FindBookingDateFilterDto, BookingDispatcherDto, AcceptOrRejectBookingDto, AssignJobDto, DriverRatingDto, DriverRatingDetailDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class BookingService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findBookingDto: FindBookingDto) {
    return await this.httpService.get<PageResultDto<BookingDto>>('/admin/v1/booking', findBookingDto)
  }

  async findDispatcher() {
    return await this.httpService.get<BookingDispatcherDto[]>('/admin/v1/booking/dispatcher')
  }

  async findOne(bookingNumber: string) {
    return await this.httpService.get<BookingDetailDto>(`/admin/v1/booking/${bookingNumber}`)
  }

  async count() {
    return await this.httpService.get<CountBookingDto>('/admin/v1/booking/count')
  }

  async exportExcel(dateFilterDto: FindBookingDateFilterDto) {
    return await this.httpService.get<ExportBookingResultDto>(`/admin/v1/booking/export`, dateFilterDto)
  }

  async accept(accpetBookingDto: AcceptOrRejectBookingDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/booking/accept`, accpetBookingDto)
  }

  async reject(rejectBookingDto: AcceptOrRejectBookingDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/booking/reject`, rejectBookingDto)
  }

  async delivery(bookingNumber: string, updateStatusDto: UpdateBookingStatusDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/booking/${bookingNumber}/delivery`, updateStatusDto)
  }

  async complete(bookingNumber: string, updateStatusDto: UpdateBookingStatusDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/booking/${bookingNumber}/complete`, updateStatusDto)
  }

  async cancel(bookingNumber: string) {
    return await this.httpService.post<Boolean>(`/admin/v1/booking/${bookingNumber}/cancel`)
  }

  async incomplete(bookingNumber: string, updateStatusDto: UpdateBookingStatusDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/booking/${bookingNumber}/incomplete`, updateStatusDto)
  }

  async arrive(bookingNumber: string, updateStatusDto: UpdateBookingStatusDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/booking/${bookingNumber}/arrive`, updateStatusDto)
  }

  async assign(bookingNumber: string, assignJobDto: AssignJobDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/booking/${bookingNumber}/assign-driver`, assignJobDto)
  }

  async findRating(bookingNumber: string) {
    return await this.httpService.get<DriverRatingDetailDto>(`/admin/v1/booking/${bookingNumber}/ratings`)
  }
}
