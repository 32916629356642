import { Injectable } from '@angular/core'

import { CreateProductCategoryDto, PageResultDto, ProductCategoryDto, UpdateProductCategoryDto } from '../dto'
import { FindProductCategoryDto } from '../dto/product-category.dto'

import { HttpService } from './http.service'


@Injectable()
export class ProductCategoryService {
  constructor(
    private httpService: HttpService
  ) {}

  async find(findProductCategoryDto: FindProductCategoryDto) {
    return await this.httpService.get<ProductCategoryDto[]>('/admin/v1/product-categories', findProductCategoryDto)
  }

  async findLastChildren() {
    return await this.httpService.get<PageResultDto<ProductCategoryDto>>('/admin/v1/product-categories/last-children')
  }

  async findChildren(searchText: string) {
    return await this.httpService.get<PageResultDto<ProductCategoryDto>>('/admin/v1/product-categories/last-children', {searchText})
  }

  async findWithParent(searchText: string, limit: number = 10, offset: number = 0) {
    return await this.httpService.get<PageResultDto<ProductCategoryDto>>('/admin/v1/product-categories/parent', {searchText, limit, offset})
  }

  async findOne(categoryId: string) {
    return await this.httpService.get<ProductCategoryDto>(`/admin/v1/product-categories/${categoryId}`)
  }

  async update(categoryId: string, { name, iconImageId, bannerImageId, displayFeatured }: UpdateProductCategoryDto) {
    return await this.httpService.patch<Boolean>(`/admin/v1/product-categories/${categoryId}`, {
      name,
      iconImageId,
      bannerImageId,
      displayFeatured
    })
  }

  async delete(categoryId: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/product-categories/${categoryId}`)
  }

  async create({ name, iconImageId, bannerImageId, parentId  }: CreateProductCategoryDto) {
    return await this.httpService.post<Boolean>('/admin/v1/product-categories', {
      name, 
      iconImageId, 
      bannerImageId, 
      parentId
    })
  }
}