import { Injectable } from '@angular/core'

import { CountStatusDto, FindServiceBookingDto, PageResultDto, ServiceBookingDetailDto, ServiceBookingDto } from '../dto'
import { HttpService } from './http.service'


@Injectable()
export class ServiceBookingService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findServiceBookingDto: FindServiceBookingDto) {
    return await this.httpService.get<PageResultDto<ServiceBookingDto>>('/admin/v1/service-bookings', findServiceBookingDto)
  }

  async findAdvanced(findServiceBookingDto: FindServiceBookingDto) {
    return await this.httpService.get<PageResultDto<ServiceBookingDto>>('/admin/v1/service-bookings/in-advanced', findServiceBookingDto)
  }

  async findOne(serviceBookingNumber: string) {
    return await this.httpService.get<ServiceBookingDetailDto>(`/admin/v1/service-bookings/${serviceBookingNumber}`)
  }

  async assign(serviceBookingNumber: string, storeId: string) {
    return await this.httpService.put<Boolean>(`/admin/v1/service-bookings/${serviceBookingNumber}/assign-store`, { storeId })
  }

  async cancel(serviceBookingNumber: string, reason: string) {
    return await this.httpService.post<Boolean>(`/admin/v1/service-bookings/${serviceBookingNumber}/cancel`, { reason })
  }

  async complete(serviceBookingNumber: string) {
    return await this.httpService.post<Boolean>(`/admin/v1/service-bookings/${serviceBookingNumber}/complete`)
  }

  async count() {
    return await this.httpService.get<CountStatusDto>('/admin/v1/service-bookings/count')
  }
}
