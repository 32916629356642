import { Injectable } from '@angular/core'

import { BankDto, CreateBankDto, FindBankDto, PageResultDto, UpdateBankDto } from '../dto'
import { HttpService } from './http.service'
@Injectable()
export class BankService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findBankDto: FindBankDto) {
    return await this.httpService.get<PageResultDto<BankDto>>('/admin/v1/bank', findBankDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<BankDto>(`/admin/v1/bank/${id}`)
  }

  async create({ bankName, imageId }: CreateBankDto) {
    return this.httpService.post<BankDto>(`/admin/v1/bank`, {
      bankName,
      imageId
    })
  }

  async update(id: string, { bankName, imageId }: UpdateBankDto) {
    return await this.httpService.put<BankDto>(`/admin/v1/bank/${id}`, { bankName, imageId })
  }

  async delete(id: string) {
    return this.httpService.delete(`/admin/v1/bank/${id}`)
  }

  async updateImage(id: string, imageId: string) {
    return await this.httpService.put(`/admin/v1/bank/${id}/image`, { "fileId": imageId })
  }

  async order(bankIds: string[]) {
    return await this.httpService.put(`/admin/v1/bank/sort-order`, { bankIds })
  }
}
