import { Injectable } from '@angular/core'

import { CreateVoucherDto, FindVoucherDto, PageResultDto, VoucherDetailDto, VoucherDto, VoucherHistoryDto } from '../dto'
import { PagingDto } from '../dto/paging.dto'

import { HttpService } from './http.service'

@Injectable()
export class VoucherService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findVoucherDto: FindVoucherDto) {
    return await this.httpService.get<PageResultDto<VoucherDto>>(`/admin/v1/vouchers`, findVoucherDto)
  }

  async findHistory(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<VoucherHistoryDto>>(`/admin/v1/vouchers/history`, pagingDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<VoucherDetailDto>(`/admin/v1/vouchers/${id}`)
  }

  async bulkCreate(createVoucherDto: CreateVoucherDto) {
    return await this.httpService.post<Boolean>('/admin/v1/vouchers', createVoucherDto)
  }

  async bulkDelete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/vouchers/${id}`)
  }
}
