import { Injectable } from '@angular/core'
import { FindPageDto, PageDto, PageResultDto } from '../dto'
import { HttpService } from './http.service'


@Injectable()
export class PageService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findPageDto: FindPageDto) {
    return await this.httpService.get<PageResultDto<PageDto>>('/admin/v1/page', findPageDto)
  }

  async findOne(pageId: string) {
    return await this.httpService.get<PageDto>(`/admin/v1/page/${pageId}`)
  }

  async updateImage(pageId: string,imageId: string) {
    return await this.httpService.put(`/admin/v1/page/${pageId}/image`, { "fileId": imageId })
  }

  async update(pageId: string, updatePageDto: PageDto) {
    return await this.httpService.put<PageDto>(`/admin/v1/page/${pageId}`, updatePageDto)
  }
}
