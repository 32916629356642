import { Injectable } from "@angular/core"

import { HttpService } from "./http.service"

import { BookingReasonDto, PageResultDto, FindBookingReasonDto, UpdateBookingReasonDto, CreateBookingReasonDto } from "../dto"

@Injectable()
export class BookingReasonService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(findBookingReasonDto: FindBookingReasonDto) {
    return await this.httpService.get<PageResultDto<BookingReasonDto>>('/admin/v1/booking-reasons', findBookingReasonDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<boolean>(`/admin/v1/booking-reasons/${id}`)
  }

  async update(id: string, updateBookingReasonDto: UpdateBookingReasonDto) {
    return await this.httpService.put<boolean>(`/admin/v1/booking-reasons/${id}`, updateBookingReasonDto)
  }

  async create(createBookingReasonDto: CreateBookingReasonDto) {
    return await this.httpService.post<boolean>(`/admin/v1/booking-reasons`, createBookingReasonDto)
  }

  async sortOrder(bookingReasonIds: string[]) {
    return await this.httpService.put<boolean>(`/admin/v1/booking-reasons/sort-order`, { bookingReasonIds })
  }
}