import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { CreatePushNotificationDto, PageResultDto, PushNotificationDto } from '../dto'
import { PagingDto } from '../dto/paging.dto'

@Injectable()
export class PushNotificationService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ limit, offset }: PagingDto) {
    return await this.httpService.get<PageResultDto<PushNotificationDto>>(`/admin/v1/push-notification`, { limit, offset })
  }

  async create(createPushNotification: CreatePushNotificationDto) {
    return await this.httpService.post<boolean>('/admin/v1/push-notification', createPushNotification)
  }
}
