import { Injectable } from "@angular/core"
import { HttpClient, HttpHeaders } from "@angular/common/http"

import { HttpService } from "./http.service"
import { CreateCustomMapDto, CustomMapDto, FindCustomMapDto, GeoLocationDto, PageResultDto, UpdateCustomMapDto } from "../dto"

@Injectable()
export class CustomMapService {
  constructor(
    private httpService: HttpService,
    private httpClient: HttpClient
    ) {
  }

  async find(findCustomMapDto: FindCustomMapDto) {
    return await this.httpService.get<PageResultDto<CustomMapDto>>(`/admin/v1/custom-maps`, findCustomMapDto)
  }

  async create(createCustomMapDto: CreateCustomMapDto) {
    return await this.httpService.post<boolean>(`/admin/v1/custom-maps`, createCustomMapDto)
  }

  async update(id: string, customMap: UpdateCustomMapDto) {
    return await this.httpService.put<boolean>(`/admin/v1/custom-maps/${id}`, customMap)
  }

  async reverseGeocode(lat: number, lng: number) {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`

    const headers = new HttpHeaders()
    return this.httpClient.get<GeoLocationDto>(url, {params: {}, headers}).toPromise()
  }

  async delete(id: string) {
    return await this.httpService.delete<boolean>(`/admin/v1/custom-maps/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<boolean>(`/admin/v1/custom-maps/${id}/enable`)
    }

    return await this.httpService.patch<boolean>(`/admin/v1/custom-maps/${id}/disable`)
  }
}