import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { CreateFeaturedProductCategoryDto, ProductCategoryDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class FeaturedProductCategoryService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find() {
    return await this.httpService.get<ProductCategoryDto[]>(`/admin/v1/product-categories/featured`)
  }

  async create(createFeaturedTenantDto: CreateFeaturedProductCategoryDto) {
    return await this.httpService.post<any>(`/admin/v1/product-categories/featured`, createFeaturedTenantDto)
  }

  async sortOrder(productCategoryIds: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/product-categories/featured/sort-order`, { productCategoryIds })
  }

  async delete(productCategoryId: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/product-categories/featured/${productCategoryId}`)
  }

  async setEnable(productCategoryId: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/product-categories/featured/${productCategoryId}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/product-categories/featured/${productCategoryId}/disable`)
  }
}
