import { Injectable } from "@angular/core"

import { HttpService } from "./http.service"

import { DashboardDto, DriverDashboardDto, FindDriverDashboardDto } from "../dto"

@Injectable()
export class DashboardService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find() {
    return await this.httpService.get<DashboardDto>('/admin/v1/dashboard')
  }

  async findDriver(findDriverDashboardDto: FindDriverDashboardDto) {
    return await this.httpService.get<DriverDashboardDto>('/admin/v1/dashboard/driver', findDriverDashboardDto)
  }
}