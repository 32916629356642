import { Injectable } from '@angular/core'

import { PageResultDto, FindPaymentTransactionDto, PaymentTransactionDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class PaymentTransactionService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findPaymentTransactionDto: FindPaymentTransactionDto) {
    return await this.httpService.get<PageResultDto<PaymentTransactionDto>>('/admin/v1/payment-transactions', findPaymentTransactionDto)
  }

  async findByOwnerId(ownerId: string, findPaymentTransactionDto: FindPaymentTransactionDto) {
    return await this.httpService.get<PageResultDto<PaymentTransactionDto>>(`/admin/v1/payment-transactions/${ownerId}`, findPaymentTransactionDto)
  }
}
