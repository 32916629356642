export const TINYMCE_CONFIG = (file_picker_callback) => {
    return {  
      height: 500,
      plugins: 'print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image imagetools link media table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount textpattern code paste',
      toolbar: 'formatselect fontselect fontsizeselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify| image | removeformat | blockquote | umlist bullist outdent indent | code  | fullscreen',
      toolbar_drawer: 'sliding',
      imagetools_toolbar: 'editimage imageoptions',
      file_picker_types: 'image',
      image_caption: true,
      relative_urls : false,
      convert_urls : false,
      paste_as_text: true,
      fontsize_formats: '8px 10px 12px 14px 16px 18px 24px 36px',
      file_picker_callback: file_picker_callback,
      image_class_list: [
        {title: 'Small', value: 'size-small'},
        {title: 'Medium', value: 'size-medium'},
        {title: 'Large', value: 'size-large'},
        {title: 'Full', value: 'size-full'},
      ],
      content_css: [
        '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
        '//www.tinymce.com/css/codepen.min.css',
      ],
      content_style: 
      'img { display: block; max-width:100%; height:auto; margin-left: auto;margin-right: auto;}'+ 
  
      'img.size-small { width:50% ; height:auto }'+ 
      'img.size-medium { width:80% ; height:auto }' + 
      'img.size-large {width:100% ; height:auto }' +
      'img.size-full {width:100% ; height:auto }' +
      'p { font-size: 16px; }'+
      'figure.align-left img {margin-left: 0px !important; margin-right: auto ; }' +
      'figure.align-left figcaption { text-align: left;}' +
  
      'figure.align-right img { display: block; margin-left: auto; margin-right: 0px !important; }' +
  
      'figure.align-center {text-align: center; }'+
      'figure.align-right center { text-align: center;}'
    }
  }
  