import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { CreateOrUpdateMenuDto, FindMenuDto, MenuDto } from '../dto'

@Injectable()
export class MenuService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findMenuDto: FindMenuDto) {
    return await this.httpService.get<MenuDto[]>('/admin/v1/menu', findMenuDto)
  }

  async update(id: string, updateMenuDto: CreateOrUpdateMenuDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/menu/${id}`, updateMenuDto)
  }

  async sortOrder(ids: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/menu/sort-order`, { ids })
  }

  async create(createMenuDto: CreateOrUpdateMenuDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/menu`, createMenuDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/menu/${id}`)
  }
}
