import { Injectable } from '@angular/core'

import { PageResultDto, FindBookingFlowDto, BookingFlowDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class BookingFlowService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findBookingDto: FindBookingFlowDto) {
    return await this.httpService.get<PageResultDto<BookingFlowDto>>('/admin/v1/booking-flow', findBookingDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<BookingFlowDto>(`/admin/v1/booking-flow/${id}`)
  }

  async completePreAuth(id: string) {
    return await this.httpService.post<Boolean>(`/admin/v1/booking-flow/${id}/complete-pre-auth`)
  }

  async cancelPreAuth(id: string) {
    return await this.httpService.post<Boolean>(`/admin/v1/booking-flow/${id}/cancel-pre-auth`)
  }
}
