import { Injectable } from '@angular/core'

import { BankInfoDto, CreateBankInfoDto, FindBankDto, PageResultDto, UpdateBankInfoDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class BankInfoService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findBankDto: FindBankDto) {
    return await this.httpService.get<PageResultDto<BankInfoDto>>('/v1/bank-info', findBankDto)
  }

  async findAdmin(findBankDto: FindBankDto) {
    return await this.httpService.get<PageResultDto<BankInfoDto>>('/admin/v1/bank-info', findBankDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<BankInfoDto>(`/v1/bank-info/${id}`)
  }

  async create(createBankInfo: CreateBankInfoDto) {
    return this.httpService.post<Boolean>(`/v1/bank-info`, createBankInfo)
  }

  async update(id: string, updateBankInfo: UpdateBankInfoDto) {
    return await this.httpService.put<Boolean>(`/v1/bank-info/${id}`, updateBankInfo)
  }

  async delete(id: string) {
    return this.httpService.delete<Boolean>(`/v1/bank-info/${id}`)
  }
}
