import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { ChannelDto, CreateChannelDto, FindChannelDto, PageResultDto, UpdateChannelDto } from '../dto'

import { HttpService } from './http.service'

@Injectable()
export class ChannelService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ searchText, limit, offset }: FindChannelDto) {
    return await this.httpService.get<PageResultDto<ChannelDto>>(`/admin/v1/channel`, { searchText, limit, offset })
  }

  async findOne(id: string) {
    return await this.httpService.get<ChannelDto>(`/admin/v1/channel/${id}`)
  }

  async create(createChannelDto: CreateChannelDto) {
    return await this.httpService.post<ChannelDto>(`/admin/v1/channel`, createChannelDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/channel/${id}`)
  }

  async update(id: string, updateChannelDto: UpdateChannelDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/channel/${id}`, updateChannelDto)
  }

  async setEnable(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/channel/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/channel/${id}/disable`)
  }
}
