import { Injectable } from '@angular/core'

import { Subject } from 'rxjs'

import { CreateDriverDto, DriverDetailDto, DriverDto, FindDriverDto, PageResultDto, UpdateDriverDto, DriverSetupDto, UserDriverDto, AvailableDriverDto, BalanceDto, ExportBookingResultDto, FindBookingDateFilterDto, CountPendingDriverDto, DriverRatingDto, FindDriverRatingDto, BookingDto } from '../dto'
import { PagingDto } from '../dto/paging.dto'
import { HttpService } from './http.service'


@Injectable()
export class DriverService {
  public locations: Subject<any>

  constructor(
    private httpService: HttpService) {
  }

  async find(findDriverDto: FindDriverDto) {
    return await this.httpService.get<PageResultDto<DriverDto>>('/admin/v1/drivers', findDriverDto)
  }

  async findAssignable(findDriverDto: FindDriverDto) {
    return await this.httpService.get<PageResultDto<DriverDto>>('/admin/v1/drivers/assignable', findDriverDto)
  }

  async findBalance(findDriverDto: FindDriverDto) {
    return await this.httpService.get<PageResultDto<BalanceDto>>('/admin/v1/drivers/balance', findDriverDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<DriverDetailDto>(`/admin/v1/drivers/${id}`)
  }

  async findBooking(id: string) {
    return await this.httpService.get<BookingDto[]>(`/admin/v1/drivers/${id}/bookings`)
  }

  async findOnePending(id: string) {
    return await this.httpService.get<DriverSetupDto>(`/admin/v1/drivers/setup/${id}`)
  }

  async update(id: string, updateDriverDto: UpdateDriverDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/drivers/${id}`, updateDriverDto)
  }

  async setEnable(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/drivers/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/drivers/${id}/disable`)
  }

  async create(createDriverDto: CreateDriverDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/drivers`, createDriverDto)
  }

  async findPending({ searchText, limit, offset }: FindDriverDto) {
    return await this.httpService.get<PageResultDto<DriverSetupDto>>('/admin/v1/drivers/setup', { searchText, limit, offset })
  }

  async countPending() {
    return await this.httpService.get<CountPendingDriverDto>('/admin/v1/drivers/setup/count')
  }

  async exportExcel(dateFilterDto: FindBookingDateFilterDto) {
    return await this.httpService.get<ExportBookingResultDto>(`/admin/v1/drivers/export-payment`, dateFilterDto)
  }

  async approve(id: string) {
    return await this.httpService.post<Boolean>(`/admin/v1/drivers/setup`, { driverSetupId: id })
  }

  async findSimulatedDriver() {
    return await this.httpService.get<UserDriverDto[]>(`/admin/v1/drivers/simulation`)
  }

  async createSimulatedDriver(numberOfBots: number) {
    return await this.httpService.post<Boolean>(`/admin/v1/drivers/simulation`, { numberOfBots })
  }

  async findAvailable(findDriverDto: FindDriverDto) {
    return await this.httpService.get<PageResultDto<AvailableDriverDto>>(`/admin/v1/drivers/available`, findDriverDto)
  }
  
  async findRatings(findDriverRatingDto: FindDriverRatingDto) {
    return await this.httpService.get<PageResultDto<DriverRatingDto>>(`/admin/v1/drivers/ratings`, findDriverRatingDto)
  }

  async exportRatingExcel(dateFilterDto: FindBookingDateFilterDto) {
    return await this.httpService.get<ExportBookingResultDto>(`/admin/v1/drivers/export-ratings`, dateFilterDto)
  }
}
