import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { PageResultDto, ProductDto, FindProductDto, ProductDetailDto, CreateOrUpdateProductDto, SetFileDto, SetFilesDto } from './../dto'
import { HttpService } from './http.service'

@Injectable()
export class ProductService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async createOrUpdate(createOrUpdateProductDto: CreateOrUpdateProductDto[]) {
    return await this.httpService.put<ProductDto>(`/admin/v1/products`, createOrUpdateProductDto)
  }

  async find(findProductDto: FindProductDto) {
    return await this.httpService.get<PageResultDto<ProductDto>>(`/admin/v1/products`, findProductDto)
  }

  async publish(id: string) {
    return await this.httpService.post(`/admin/v1/products/${id}/publish`)
  }

  async findOne(id: string) {
    return await this.httpService.get<ProductDetailDto>(`/admin/v1/products/${id}`)
  }

  async findFeatured(findProductDto: FindProductDto) {
    return await this.httpService.get<ProductDto[]>(`/admin/v1/products/featured`, findProductDto)
  }

  async createFeatured(productId: string) {
    return await this.httpService.post<Boolean>(`/admin/v1/products/featured`, {productId})
  }

  async sortOrderFeatured(productIds: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/products/featured/sort-order`, {productIds})
  }

  async deleteFeatured(productId: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/products/featured/${productId}`)
  }

  async deleteOne(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/products/${id}`)
  }

  async setThumbnail(productId: string, setFileDto: SetFileDto) {
    return await this.httpService.put<ProductDetailDto>(`/admin/v1/products/${productId}/thumbnail`, setFileDto)
  }

  async setImages(productId: string, setFilesDto: SetFilesDto) {
    return await this.httpService.put<ProductDetailDto>(`/admin/v1/products/${productId}/images`, setFilesDto)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put<Boolean>(`/admin/v1/products/${id}/enable`)
    }
    
    return await this.httpService.put<Boolean>(`/admin/v1/products/${id}/disable`)
  }

  async findAll(findProductDto: FindProductDto) {
    return await this.httpService.get<ProductDto[]>(`/admin/v1/products/all`, findProductDto)
  }
}
