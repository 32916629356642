import { Injectable } from '@angular/core'

import { BannerDetailDto, BannerItemDto, CreateOrUpdateBannerDto, ResultDto } from '../dto'
import { BannerDto, UpdateBannerDto } from '../dto/banner.dto'

import { HttpService } from './http.service'


@Injectable()
export class BannerService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<BannerDto[]>('/admin/v1/banners')
  }

  async findOneBanner(bannerId: string) {
    return await this.httpService.get<BannerDetailDto>(`/admin/v1/banners/${bannerId}`)
  }

  async updateBanner(bannerId: string, bannerItemId: string, updatePageDto: UpdateBannerDto) {
    return await this.httpService.put<ResultDto>(`/admin/v1/banners/${bannerId}/items/${bannerItemId}/enable`, updatePageDto)
  }

  async fineOneBannerItem(bannerId: string, bannerItemId: string) {
    return await this.httpService.get<BannerItemDto>(`/admin/v1/banners/${bannerId}/items/${bannerItemId}`)
  }
  
  async createItemBanner(bannerId: string, createOrUpdateBannerDto: Partial<CreateOrUpdateBannerDto>)  {
    return await this.httpService.post<ResultDto>(`/admin/v1/banners/${bannerId}/items`, createOrUpdateBannerDto)
  }

  async UpdateBannerItem(bannerId: string, bannerItemId: string, createOrUpdateBannerDto: Partial<CreateOrUpdateBannerDto>) {
    return await this.httpService.put<ResultDto>(`/admin/v1/banners/${bannerId}/items/${bannerItemId}`, createOrUpdateBannerDto)
  }

  async deleteBannerItem(bannerId: string, bannerItemId: string) {
    return await this.httpService.delete(`/admin/v1/banners/${bannerId}/items/${bannerItemId}`)
  }

  async updateSortOrder(ids: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/featured-banners/sort-order`, { ids })
  }

  async deleteFeaturedBanner(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/featured-banners/${id}`)
  }
}
