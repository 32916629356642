import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { FeatureDto } from '../dto'

@Injectable()
export class LocationService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<FeatureDto>('/admin/v1/location')
  }

  async update(locationId: string, geometry: any) {
    return await this.httpService.put<FeatureDto>(`/admin/v1/location/${locationId}`, { geometry })
  }
}
