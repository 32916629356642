import { Injectable } from '@angular/core'

import {  CountStatusDto, FindOrderDto, OrderDetailDto, OrderDto, PageResultDto } from '../dto'
import { HttpService } from './http.service'


@Injectable()
export class OrderService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findOrderDto: FindOrderDto) {
    return await this.httpService.get<PageResultDto<OrderDto>>('/admin/v1/orders', findOrderDto)
  }

  async findOne(orderNumber: string) {
    return await this.httpService.get<OrderDetailDto>(`/admin/v1/orders/${orderNumber}`)
  }

  async count() {
    return await this.httpService.get<CountStatusDto>('/admin/v1/orders/count')
  }
}
